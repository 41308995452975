<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0"
                    :span="24"
                    class="organizetoolbar"
                    style="padding-bottom: 0px">
                <el-form :inline="true"
                         ref="selectForm"
                         :model="selectForm"
                         class="select_form">
                    <el-form-item label="文件名称:">
                        <el-input type="text" v-model="selectForm.Name"></el-input>
                    </el-form-item>
                    <el-form-item label="类型:">
                        <el-select v-model="selectForm.type" placeholder="所属类型" style="width: 100%" clearable>
                            <el-option label="社保缴纳标准要求" value="社保缴纳标准要求"></el-option>
                            <el-option label="公积金缴纳标准要求" value="公积金缴纳标准要求"></el-option>
                            <el-option label="其他福利政策标准" value="其他福利政策标准"></el-option>
                            <el-option label="试用期管理要求" value="试用期管理要求"></el-option>
                            <el-option label="招聘及管理要求" value="招聘及管理要求"></el-option>
                            <el-option label="竞聘调岗管理要求" value="竞聘调岗管理要求"></el-option>
                            <el-option label="岗位及编制管理要求" value="岗位及编制管理要求"></el-option>
                        </el-select>
                    </el-form-item>
                    <toolbar :buttonList="buttonList"
                             @callFunction="callFunction"
                             :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="handleShow"
                      class="new_table">
                <el-table-column prop="name" label="文件名称">
                    <template slot-scope="scope">
                        <a :href="scope.row.path" target="_blank">{{scope.row.name}}</a>
                    </template>

                </el-table-column>
                <el-table-column prop="type" label="类型"></el-table-column>
                <el-table-column prop="createDate" label="创建时间" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="是否在用" prop="isEnabled" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isEnabled === true ? 'success':'info'"
                                disable-transitions>{{scope.row.isEnabled? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog :title="optType"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型:" prop="type" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.type" placeholder="所属类型" style="width: 100%" :disabled="optDisabled" clearable>
                                <el-option label="社保缴纳标准要求" value="社保缴纳标准要求"></el-option>
                                <el-option label="公积金缴纳标准要求" value="公积金缴纳标准要求"></el-option>
                                <el-option label="其他福利政策标准" value="其他福利政策标准"></el-option>
                                <el-option label="试用期管理要求" value="试用期管理要求"></el-option>
                                <el-option label="招聘及管理要求" value="招聘及管理要求"></el-option>
                                <el-option label="竞聘调岗管理要求" value="竞聘调岗管理要求"></el-option>
                                <el-option label="岗位及编制管理要求" value="岗位及编制管理要求"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="文件名称:" prop="name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.name" :disabled="!operation1"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="SakaryForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
        GetDocumentList,
        DelDocument,
        PutDocumentEnabled,
        PostDocumentInfo,
        GetDocument,

} from "../../api/hr";
import UserChoose from "../../components/UserChoose";
import UploadFiles from "../../components/UploadFiles";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UploadFiles },
  data() {
    return {
      searchVal: "",
      selectForm: {
          Name: "",
          type:"",
      },
      loading: true,
      choosedusers: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      addUserVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation1: true,

      optType:"", // 什么操作 新增，修改，解除

      buttonList: [],
      SakaryForm: {
          name: "",
          path: "",
          type: "",
          FileArry: [],
      },
      pickerOptions:{
          disabledDate: time => {
              return this.selectTime(time);//调用方法
          }
      },
      effectiveDate:new Date('2000-05-24 23:06:31'), // 有效日期
    };
  },
  computed:{
      // 修改用
      optDisabled: function () {
          if (this.operation1) {
              if (this.optType == "新增")
                  return false;
              return true;
          }
          return !this.operation1
      },
  },
  methods: {
      selectTime(time) {
          return time.getTime() < this.effectiveDate.getTime()
      },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
   
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
          name: this.selectForm.Name,
          type: this.selectForm.type,
      };
        GetDocumentList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
      handleEdit() {
          //this.optType = "修改";
          //let row = this.currentRow;
          //if (!row) {
          //    this.$message({
          //        message: "请选择要编辑的一行数据！",
          //        type: "error",
          //    });
          //    return;
          //}
          //console.log(row);
          //GetDicContractTypePageList().then((res) => {
          //    this.CertTypeLists = res.data.response.data;
          //});
          //GetContractDetail({ id: row.Id }).then((res) => {
          //    console.log(res);
          //    this.SakaryForm = res.data.response;
          //    this.key += 1;
          //    this.SakaryVisible = true;
          //});
          //this.operation = false;
          //this.operation1 = true;
      },
      setEnabled() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要设置的一行数据！",
                  type: "error",
              });
              return;
          }
          this.$confirm("确定要进行此操作吗？", "提示", {}).then(() => {
              let para = {
                  docId: row.id,
              };
              PutDocumentEnabled(row.id).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "操作成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: "操作失败",
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
      // 删除
      handleDelete() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要删除的一行数据！",
                  type: "error",
              });
              return;
          }
          this.$confirm("确定要删除吗？", "提示", {}).then(() => {
              let para = {
                  id: row.id,
              };
              DelDocument(para).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "删除成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: "删除失败",
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
  handleShow() {
      //this.optType = "查看";
      //let row = this.currentRow;
      //if (!row) {
      //  this.$message({
      //    message: "请选择要查看的一行数据！",
      //    type: "error",
      //  });
      //  return;
      //}
      //GetContractDetail({ id: row.Id }).then((res) => {
      //  console.log(res);
      //  this.SakaryForm = res.data.response;
      //  this.key += 1;
      //  this.SakaryVisible = true;
      //});
      //this.operation = false;
      //this.operation1 = false;
    },
      handleAdd() {
          this.optType = "新增";
          (this.SakaryForm = {
              name: "",
              path: "",
              type: "",
              FileArry: [],
      }),
      this.key += 1;
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
                PostDocumentInfo(para).then((res) => {
                    if (res.data.success) {
                        this.$message({
                            message: "添加成功",
                            type: "success",
                            duration: 5000,
                        });
                        this.SakaryVisible = false;
                        this.getData();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                            duration: 5000,
                        });
                    }
                });
            } else {
                //修改
                switch (this.optType) {
                    case "新增":
                        PostDocumentInfo(para).then((res) => {
                            if (res.data.success) {
                                this.$message({
                                    message: "修改成功",
                                    type: "success",
                                    duration: 5000,
                                });
                                this.SakaryVisible = false;
                                this.getData(false);
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                    duration: 5000,
                                });
                            }
                        });
                        break;
                }
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
      },

    dealFiles(data) {
        console.log("data.backData", data);
        if (data.backData.length < 1)
            return;
        if (this.SakaryForm.FileArry.length > 1)
            return;
        this.SakaryForm.FileArry = data.backData;
        this.SakaryForm.path = data.backData[0].URL
        this.SakaryForm.name = data.backData[0].FileName
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
      this.buttonList = getButtonList(this.$route.path, routers, buttons);
      console.log("this.buttonList:", this.buttonList);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

